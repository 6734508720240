import "./App.css";
import React, {Suspense} from 'react'
import {
  useRoutes,
  Navigate,
  BrowserRouter as Router
} from "react-router-dom";

import Preloader from "./components/preloader/preloader";
import { Form } from "react-bootstrap";

const Navbar = React.lazy(()=>import('./components/navbar'))
const LandingPage = React.lazy(()=>import('./pages/home'))
const About = React.lazy(()=>import('./pages/about/about'))
const ContactPage = React.lazy(()=>import('./pages/contactus/contact'))
const UserExperience = React.lazy(()=>import('./pages/innerPage/userExperience/userExperience'))
const WeDevelop = React.lazy(()=>import('./pages/landingPages/weDevelop'))
const WeDesign = React.lazy(()=>import('./pages/landingPages/weDesign'))
const Footer = React.lazy(()=>import('./components/footer/footer'))
const WebApplication = React.lazy(()=>import('./pages/innerPage/webApplication/webApplication'))
const DesktopApplication = React.lazy(()=>import('./pages/innerPage/desktopApplication/desktopApplication'))
const Websites = React.lazy(()=>import('./pages/innerPage/websites/websites'))
const MobileApplication = React.lazy(()=>import('./pages/innerPage/mobileApplication/mobileApplication'))
const UserInterface = React.lazy(()=>import('./pages/innerPage/userInterface/userInterface'))
const LogoDesign = React.lazy(()=>import('./pages/innerPage/logoDesign/logoDesign'))
const DigitalMarketing = React.lazy(()=>import('./pages/innerPage/digitalMarketing/DigitalMarketing'))
const GraphicsDesign = React.lazy(()=>import('./pages/innerPage/graphicsDesign/graphicsDesign'))
const Privacy = React.lazy(()=>import('./pages/privacynterms/privacy.js'))
const Terms = React.lazy(()=>import('./pages/privacynterms/terms.js'))
const NotFound = React.lazy(()=>import('./pages/404'))
const Casecod = React.lazy(()=>import('./pages/casestudyinnerpages/chefondial'))
const Career =  React.lazy(()=>import('./pages/career/career'))
const UIUXCareer =  React.lazy(()=>import('./pages/careerInnerPages/uiuxcareer'))
const ContentCareer =  React.lazy(()=>import('./pages/careerInnerPages/contentcareer'))
const DigitalCareer =  React.lazy(()=>import('./pages/careerInnerPages/digitalcareer'))
const GraphicCareer =  React.lazy(()=>import('./pages/careerInnerPages/graphiccareer'))
const FrontendCareer =  React.lazy(()=>import('./pages/careerInnerPages/frontendcareer'))
const BackendCareer =  React.lazy(()=>import('./pages/careerInnerPages/backendcareer'))



function App() {
  const RouteData = [
    { path: "/", element: <LandingPage/> },
    { path: "/about-us", element: <About /> },
    {path:"/contact", element: <ContactPage />},
    {path:"/weDevelop",element:<WeDevelop/>},
    {path:"/weDesign",element:<WeDesign/>},
    {path:"/webApplication", element: <WebApplication/>},
    {path:"/websites", element: <Websites/>},
    {path:"/`desktopApplication`", element: <DesktopApplication/>},
    {path:"/mobileApplication", element: <MobileApplication/>},
    {path:"/userExperience", element: <UserExperience/>},
    {path:"/userInterface", element: <UserInterface/>},
    {path:"/logoDesign", element: <LogoDesign/>},
    {path:"/DigitalMarketing", element: <DigitalMarketing/>},
    {path:"/graphicDesign", element: <GraphicsDesign/>},
    { path:"*", element:<Navigate to="/404" />}, 
    {path:"/404",element:<NotFound/>},
    
    {path:"/TermsAndCondition", element: <Terms/>},
    {path:"/PrivacyAndPolicy", element: <Privacy/>},
    {path:"/casecod", element: <Casecod/>},
    {path:"/career", element:<Career/>},
    {path:"/uiuxcareer", element:<UIUXCareer/>},
    {path:"/contentcareer", element:<ContentCareer/>},
    {path:"/digitalcareer", element:<DigitalCareer/>},
    {path:"/graphiccareer", element:<GraphicCareer/>},
    {path:"/frontendcareer", element:<FrontendCareer/>},
    {path:"/backendcareer", element:<BackendCareer/>},

  ];




  
  const Wrapper = ()=>{
     let routes = useRoutes(RouteData)
     return routes
  }


  return (  
    
    <Suspense fallback = {<Preloader/>}>
        <Router> 
    <Navbar routeData={RouteData}/>
      <div className = "wrapperContainer">
        <Wrapper />
      <Footer/>  
      </div>
    </Router>

      </Suspense>  
  
   );
}

export default App;
