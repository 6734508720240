import React from 'react'
import "./preloader.css"
import Circle from "../../Assets/Preloader/Circle.svg"
import Square from "../../Assets/Preloader/Square.svg"

function Preloader() {
    return (
        <div  className="preloader">
            <img src = {Circle} alt = 'logo'/>
            <img src = {Square} alt = 'logoInner'/>
    </div>
    )
}

export default Preloader
